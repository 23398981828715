import http from '../../http-common'

export const findAll = (customerId, token) => {
	return http.get(`/api/customers/${customerId}/invoices`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const getLines = (customerId, invoiceNum, token) => {
	return http.get(`/api/customers/${customerId}/invoices/${invoiceNum}/lines`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const print = (customerId, invoiceNum, token) => {
	return http.get(`/api/customers/${customerId}/invoices/${invoiceNum}/print`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const paymentIntent = (customerId, invoiceNum, token, body) => {
	return http.post(
		`/api/customers/${customerId}/payments/${invoiceNum}/preauth`,
		body,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const paymentCreate = (customerId, invoiceNum, token, body) => {
	return http.post(
		`/api/customers/${customerId}/payments/${invoiceNum}`,
		body,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}
