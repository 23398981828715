import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import ImpersonateModal from './impersonate/impersonate.modal.component'
import { useDispatch, useSelector } from 'react-redux'
import { hideImpersonateModal } from '../state/impersonatedCustomer'
import ImpersonatedUserTopBar from './impersonate/impersonated.user.top.bar.component'
import SideBar from './sidebar/sidebar.component'
import Footer from './sidebar/footer.component'
import Header from './header/header.component'
import useAuth from '../auth/useAuth'

const isClient = (currentCompany) => !currentCompany.customerId

const MainContent = () => {
	const { currentCompany } = useAuth()
	const dispatch = useDispatch()
	const show = useSelector((state) => state.impersonate.showingModal)

	return (
		<Container fluid className="d-flex flex-column flex-grow-1">
			{isClient(currentCompany) && (
				<ImpersonateModal
					show={show}
					onSelect={() => dispatch(hideImpersonateModal())}
					onClose={() => dispatch(hideImpersonateModal())}
				/>
			)}
			<div className="flex-grow-1">
				<Outlet />
			</div>

			<Footer />
		</Container>
	)
}

const Main = () => {
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)

	return (
		<Container fluid md="true" className="d-flex flex-column min-vh-100">
			{impersonatedCustomer && (
				<Row>
					<ImpersonatedUserTopBar />
				</Row>
			)}
			<Row className="row-fluid flex-column flex-md-row flex-md-nowrap flex-grow-1">
				<Col className="sidenav" md="auto">
					<SideBar />
				</Col>
				<Col className="d-flex flex-column">
					<Header />
					<MainContent />
				</Col>
			</Row>
		</Container>
	)
}
export default Main
