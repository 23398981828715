import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import QueriesList from './queries.list.component'
import QueryForm from './query.form.component'
import RunQueryForm from './run.query.form.component'
import useAuth from '../../auth/useAuth'
import { useRefresher } from '../shared/useRefresher'

const Queries = () => {
	const [isFormOpen, setIsFormOpen] = useState(false)
	const [isRunFormOpen, setIsRunFormOpen] = useState(false)
	const [mode, setMode] = useState('create')
	const [query, setQuery] = useState(null)
	const refresher = useRefresher()
	const { currentCompany } = useAuth()

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Queries</Breadcrumb.Item>
				</Breadcrumb>
				{currentCompany?.role?.name.toLowerCase() === 'admin' ? (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setMode('create')
							setIsFormOpen(true)
						}}
					>
						Add Query
					</Button>
				) : (
					''
				)}
			</div>
		)
	}

	const showEditForm = (query) => {
		setMode('edit')
		setQuery(query)
		setIsFormOpen(true)
	}

	const showRunForm = (query) => {
		setIsRunFormOpen(true)
		setQuery(query)
	}

	return (
		<>
			<HeaderComponent />
			<QueriesList
				onEditQuery={showEditForm}
				onRunQuery={showRunForm}
				refresher={refresher}
				companyRole={currentCompany?.role?.name}
			/>
			<QueryForm
				show={isFormOpen}
				mode={mode}
				onClose={() => setIsFormOpen(false)}
				onOperationCompleted={refresher.refresh}
				query={query}
			/>
			{query && (
				<RunQueryForm
					show={isRunFormOpen}
					onClose={() => setIsRunFormOpen(false)}
					query={query}
				/>
			)}
			<refresher.Toaster />
		</>
	)
}

export default Queries
