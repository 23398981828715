//TODO: Move this to Customer service
import http from '../../http-common'

export const findAll = (customerId, service, token) => {
	return http.get(`/api/customers/${customerId}/${service}`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const create = (
	data,
	environmentId,
	companyId,
	customerId,
	service,
	token,
) => {
	return http.post(
		`/api/customers/${customerId}/${service}`,
		{ data, environmentId, companyId },
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const shipViaList = (companyId, environmentId, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/ship-via`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const partSearch = (environmentId, companyId, partNum, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/part?partNum=${partNum}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const partInfo = (companyId, environmentId, partNum, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/part/${partNum}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const itemPriceByQty = (customerId, shipToNum, part, qty, token) => {
	return http.post(
		`/api/customers/${customerId}/orders/item-price`,
		{ part, qty, shipToNum },
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}
