import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import ConnectionForm from './connection.form.component'
import ConnectionsList from './connections.list.component'
import { useRefresher } from '../shared/useRefresher'

const EpicorConnections = () => {
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const [envId, setEnvId] = useState(null)
	const refresher = useRefresher()

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Epicor Connections</Breadcrumb.Item>
				</Breadcrumb>

				<Button
					style={{ float: 'right' }}
					onClick={() => {
						setMode('create')
						setShowForm(true)
					}}
				>
					Add Connection
				</Button>
			</div>
		)
	}

	const showEditForm = (id) => {
		setMode('edit')
		setEnvId(id)
		setShowForm(true)
	}

	const showVerifyForm = (id) => {
		setMode('connect')
		setEnvId(id)
		setShowForm(true)
	}

	return (
		<>
			<HeaderComponent />
			<ConnectionsList
				refresher={refresher}
				onCardClick={showEditForm}
				onVerify={showVerifyForm}
			/>
			<ConnectionForm
				show={showForm}
				mode={mode}
				onClose={() => {
					setShowForm(false)
					refresher.refresh()
				}}
				onOperationCompleted={refresher.refresh}
				envId={envId}
			/>
			<refresher.Toaster />
		</>
	)
}

export default EpicorConnections
