import { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { useSessionCookies } from './useSessionCookies'

const AuthContext = createContext({})

export function AuthProvider({ children }) {
	const {
		currentCompany,
		user,
		loading,
		setCurrentCompany,
		setUser,
		clearSession,
	} = useSessionCookies()

	const signin = async (email, password) => {
		const headers = new Headers({
			Accept: 'application/json',
			'Content-type': 'application/json',
		})
		const body = JSON.stringify({ email, password })

		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/auth`,
			{ method: 'POST', headers, body },
		)
		if (!response.ok) {
			console.log('failed to login', response)
			const text = await response.text()
			const error = JSON.parse(text)
			throw new Error(error.message)
		}

		const ans = await response.json()
		return setUser(ans)
	}

	const signout = () => {
		clearSession()
	}

	const settleOnCompany = (customerOrClient, options = {}) => {
		setCurrentCompany(customerOrClient, options)
	}

	const updatePassword = async (token, email, newPassword) => {
		const headers = new Headers({
			'x-access-token': token,
			Accept: 'application/json',
			'Content-type': 'application/json',
		})
		const body = JSON.stringify({ email: email, password: newPassword })

		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset/update-password`,
			{ method: 'POST', headers, body },
		)
		if (!response.ok) {
			console.log('failed to reset password', response)
			throw new Error(response.statusText)
		}

		const ans = await response.json()
		return setUser(ans)
	}

	const value = {
		user,
		loading,
		signin,
		signout,
		currentCompany,
		settleOnCompany,
		updatePassword,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider')
	}

	return context
}

export function RequireAuth({ children }) {
	const { user, currentCompany, loading } = useAuth()
	const location = useLocation()
	if (loading) return <div>Loading...</div>
	return user && currentCompany ? (
		children
	) : (
		<Navigate to="/login" replace state={{ path: location.pathname }} />
	)
}

export const resetPassword = async (email) => {
	const headers = new Headers({
		Accept: 'application/json',
		'Content-type': 'application/json',
	})
	const body = JSON.stringify({ email })

	const ans = await fetch(
		`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset`,
		{
			method: 'POST',
			headers,
			body,
		},
	).then((response) => {
		if (!response.ok) {
			console.log('failed to send reset password request/message', response)
			throw new Error(response.statusText)
		} else return response.json()
	})

	return ans
}

export function useCustomerId() {
	const { currentCompany } = useAuth()
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	return impersonatedCustomer?.id ?? currentCompany?.attributes?.id ?? undefined
}
