import { useCallback, useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Col,
	Form,
	Row,
	Spinner,
	Container,
} from 'react-bootstrap'
import useAuth, { useCustomerId } from '../../../auth/useAuth'
import {
	EpicorCustomerService,
	EpicorSharedService,
} from '../../../services/epicor'
import AsyncPartTypeahead from '../../shared/part.search.component'

const NewHelpDesk = ({ onSubmitted }) => {
	const { user, currentCompany } = useAuth()
	const [error, setError] = useState(null)
	const [shipToAddresses, setShipToAddresses] = useState([])
	const [shipToNum, setShipToNum] = useState('')
	const [conNum, setConNum] = useState(0)
	const [contacts, setContacts] = useState([])
	const [orderNum, setOrderNum] = useState(0)
	const [orders, setOrders] = useState([])
	const [ordersLoading, setOrdersLoading] = useState(false)
	const [rmaNum, setRMANum] = useState(0)
	const [rmas, setRMAs] = useState([])
	const [rmasLoading, setRMAsLoading] = useState(false)
	const [invoiceNum, setInvoiceNum] = useState(0)
	const [invoices, setInvoices] = useState([])
	const [invoicesLoading, setInvoicesLoading] = useState(false)
	const [quoteNum, setQuoteNum] = useState(0)
	const [quotes, setQuotes] = useState([])
	const [quotesLoading, setQuotesLoading] = useState(false)
	const [partNum, setPartNum] = useState('')
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [savingHelpDesk, setSavingHelpDesk] = useState(false)

	const customerId = useCustomerId()

	const createHelpDeskPayload = () => {
		return {
			helpdesk: {
				shipTo: shipToNum,
				issueSummary: title,
				issueText: description,
				orderNum: orderNum,
				rmaNum: rmaNum,
				invoiceNum: invoiceNum,
				quoteNum: quoteNum,
				partNum: partNum,
				conNum: conNum,
			},
		}
	}

	const submit = async (event) => {
		event.preventDefault()
		setSavingHelpDesk(true)

		try {
			const response = await EpicorSharedService.create(
				createHelpDeskPayload(),
				currentCompany.company.environmentId,
				currentCompany.company.company1,
				customerId,
				'helpdesks',
				user.accessToken,
			)
			const { error } = response.data
			if (error) {
				setError(error[0]?.ErrorText ?? JSON.stringify(error))
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setSavingHelpDesk(false)
		}
	}

	const loadSelect = async (list, setList, loading, setLoading, service) => {
		if (list.length === 0 && !loading) {
			setLoading(true)
			try {
				const response = await EpicorSharedService.findAll(
					customerId,
					service,
					user.accessToken,
				)
				setList(response.data)
			} catch (error) {
				setError(error.message)
			} finally {
				setLoading(false)
			}
		}
	}

	const fetchAddressesAndContacts = useCallback(async () => {
		try {
			const [infoResponse, contactsResponse] = await Promise.all([
				EpicorCustomerService.customerInfo(customerId, user.accessToken),
				EpicorCustomerService.customerContacts(customerId, user.accessToken),
			])
			setShipToAddresses(
				infoResponse.data.shipToAddresses.sort((a, b) => a.name > b.name),
			)
			setContacts(contactsResponse.data.value.sort((a, b) => a.name > b.name))
		} catch (error) {
			setError(error.message)
		}
	}, [customerId, user.accessToken])

	useEffect(() => {
		fetchAddressesAndContacts()
	}, [fetchAddressesAndContacts])

	return (
		<Form onSubmit={submit}>
			<Container fluid>
				<Row>
					<Col xs={12} lg={6}>
						<Form.Group controlId="form.shipTo">
							<Form.Label>Shipping Address</Form.Label>
							<Form.Select
								name="shipTo"
								onChange={(e) => setShipToNum(e.target.value)}
								value={shipToNum || ''}
							>
								{shipToAddresses.map((address) => (
									<option key={address.shipToNum} value={address.shipToNum}>
										{address.name}-{address.address1}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.contact">
							<Form.Label>Contact</Form.Label>
							<Form.Select
								name="contact"
								onChange={(e) => setConNum(e.target.value)}
								value={conNum || ''}
							>
								{contacts.map((contact) => (
									<option key={contact.ConNum} value={contact.ConNum}>
										{contact.ContactName}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.orderNum">
							<Form.Label>Order</Form.Label>
							<Form.Select
								name="order"
								onChange={(e) => setOrderNum(e.target.value)}
								value={orderNum}
								onClick={() =>
									loadSelect(
										orders,
										(data) =>
											setOrders(data.sort((a, b) => a.orderNum < b.orderNum)),
										ordersLoading,
										setOrdersLoading,
										'orders',
									)
								}
							>
								{ordersLoading ? (
									<option value="loading">Loading...</option>
								) : (
									<>
										<option value="0"> Select One...</option>
										{orders.map((order) => (
											<option key={order.orderNum} value={order.orderNum}>
												{order.orderNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.invoiceNum">
							<Form.Label>Invoice</Form.Label>
							<Form.Select
								name="invoice"
								onChange={(e) => setInvoiceNum(e.target.value)}
								value={invoiceNum}
								onClick={() =>
									loadSelect(
										invoices,
										(data) =>
											setInvoices(
												data.sort((a, b) => a.invoiceNum < b.invoiceNum),
											),
										invoicesLoading,
										setInvoicesLoading,
										'invoices',
									)
								}
							>
								{invoicesLoading ? (
									<option value="loading">Loading...</option>
								) : (
									<>
										<option value="0"> Select One...</option>
										{invoices.map((invoice) => (
											<option
												key={invoice.invoiceNum}
												value={invoice.invoiceNum}
											>
												{invoice.invoiceNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.quoteNum">
							<Form.Label>Quote</Form.Label>
							<Form.Select
								name="quote"
								onChange={(e) => setQuoteNum(e.target.value)}
								value={quoteNum || ''}
								onClick={() =>
									loadSelect(
										quotes,
										(data) =>
											setQuotes(data.sort((a, b) => a.quoteNum < b.quoteNum)),
										quotesLoading,
										setQuotesLoading,
										'quotes',
									)
								}
							>
								{quotesLoading ? (
									<option value="loading">Loading...</option>
								) : (
									<>
										<option value="0"> Select One...</option>
										{quotes.map((quote) => (
											<option key={quote.quoteNum} value={quote.quoteNum}>
												{quote.quoteNum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.rmaNum">
							<Form.Label>RMA</Form.Label>
							<Form.Select
								name="rma"
								onChange={(e) => setRMANum(e.target.value)}
								value={rmaNum || ''}
								onClick={() =>
									loadSelect(
										rmas,
										(data) => setRMAs(data.sort((a, b) => a.rMANum < b.rMANum)),
										rmasLoading,
										setRMAsLoading,
										'rmas',
									)
								}
							>
								{rmasLoading ? (
									<option value="loading">Loading...</option>
								) : (
									<>
										<option value="0"> Select One...</option>
										{rmas.map((rma) => (
											<option key={rma.rMANum} value={rma.rMANum}>
												{rma.rMANum}
											</option>
										))}
									</>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="form.partNum">
							<Form.Label>Part</Form.Label>
							<AsyncPartTypeahead
								ident="partNumSelect"
								onChange={(selected) => setPartNum(selected)}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg={6}>
						<Form.Group controlId="form.title">
							<Form.Label>Title</Form.Label>
							<Form.Control
								name="title"
								placeholder="Title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								className="mb-3"
							/>
						</Form.Group>
						<Form.Group controlId="form.desription">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={10}
								name="description"
								placeholder="Please, put your case description here."
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								className="mb-3"
							/>
						</Form.Group>
						{currentCompany?.settings?.helpdeskNewMessage ? (
							<Alert variant="warning">
								{currentCompany?.settings?.helpdeskNewMessage}
							</Alert>
						) : (
							''
						)}
						<Button
							variant="primary mt-auto"
							type="submit"
							disabled={savingHelpDesk}
							className="mb-3"
						>
							{savingHelpDesk ? (
								<Spinner animation="border" role="status">
									<span className="visually-hidden">
										Saving Case, please wait...
									</span>
								</Spinner>
							) : (
								'Send Case'
							)}
						</Button>
						{error ? (
							<Alert
								onClose={() => setError(null)}
								variant="danger"
								dismissible
							>
								{JSON.stringify(error)}
							</Alert>
						) : (
							''
						)}
					</Col>
				</Row>
			</Container>
		</Form>
	)
}

export default NewHelpDesk
