import moment from 'moment'
import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printInvoice, payInvoice) => [
	{
		Header: 'Invoice List',
		columns: [
			{
				Header: 'Invoice#',
				accessor: 'invoiceNum',
			},
			{
				Header: 'Invoice Status',
				accessor: (item) =>
					item.openInvoice ? (
						item.pendingPayment ? (
							<Badge bg="warning">Pending Payment</Badge>
						) : (
							<Badge bg="success">Open</Badge>
						)
					) : (
						''
					),
			},
			{
				Header: 'Credit Memo',
				accessor: (item) => (item.creditMemo ? <Badge>CM</Badge> : ''),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.dueDate != null ? moment(item.dueDate).format('YYYY-MM-DD') : '',
			},
			{
				Header: 'Invoice Amount',
				accessor: (item) =>
					Intl.NumberFormat(navigator.language, {
						style: 'currency',
						currency: item.currencyCodeCurrencyID.toLowerCase(),
					}).format(item.invoiceAmt),
			},
			{
				Header: 'Invoice Balance',
				accessor: (item) =>
					Intl.NumberFormat(navigator.language, {
						style: 'currency',
						currency: item.currencyCodeCurrencyID.toLowerCase(),
					}).format(item.invoiceBal),
			},
			{
				Header: '⚡',
				accessor: (item) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printInvoice(item.invoiceNum)}
								>
									Download Invoice
								</Dropdown.Item>
								{item.openInvoice &&
									item.invoiceBal > 0 &&
									!item.pendingPayment &&
									item.stripeEnabled && (
										<Dropdown.Item
											eventKey="2"
											onClick={() => payInvoice(item)}
										>
											Pay Invoice
										</Dropdown.Item>
									)}
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
