import { Modal } from 'react-bootstrap'
import NewHelpDesk from './new-helpdesk/new.helpdesk.component'

const HelpDeskForm = ({
	show = false,
	mode = 'create',
	onClose,
	onOperationCompleted,
}) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new Case has been added. Case number: ${data.hdCaseNum}`,
		)
		onClose?.()
	}

	const FormContent = () => {
		switch (mode) {
			case 'create':
				return <NewHelpDesk onSubmitted={notifyCreated} />
			default:
				throw new Error('Unrecognized mode.')
		}
	}

	return (
		<Modal
			fullscreen
			show={show}
			onHide={onClose}
			animation
			contentClassName="p-3"
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Case</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default HelpDeskForm
