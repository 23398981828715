import { Link } from 'react-router-dom'
import {
	House,
	People,
	Gear,
	Share,
	FiletypeSql,
	Eyeglasses,
	Truck,
	Table,
	Tools,
	Bag,
	ClipboardData,
	Bank,
	CardText,
} from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'
import useAuth from '../../auth/useAuth'
import { showImpersonateModal } from '../../state/impersonatedCustomer'

const isClient = (currentCompany) => currentCompany?.isClient === 1

const Options = () => {
	const { currentCompany } = useAuth()

	const roleKey = currentCompany?.role
		? `${currentCompany.role.roleType}_${currentCompany.role.name}`.toLowerCase()
		: 'customer_ap'
	switch (roleKey) {
		case 'customer_admin':
			return <CustomerMenuOptions />
		case 'customer_ap':
			return <CustomerMenuOptions />
		case 'customer_ar':
			return <CustomerMenuOptions />
		case 'client_ap':
			return <ClientMenuOptions />
		case 'client_ar':
			return <ClientMenuOptions />
		case 'client_admin':
			return <ClientAdminMenuOptions />
		case 'superadmin_superuser':
			return <ClientAdminMenuOptions />
		default:
			return <CustomerMenuOptions />
	}
}

const ClientAdminMenuOptions = () => {
	const dispatch = useDispatch()
	return (
		<>
			<Link to="/home" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			<Link to="/users" className="nav-link">
				<People className="d-none d-lg-inline" /> User Management
			</Link>
			<Link to="/settings" className="nav-link">
				<Gear className="d-none d-lg-inline" /> Settings
			</Link>
			<Link to="/epicor-connection" className="nav-link">
				<Share className="d-none d-lg-inline" /> Epicor connection
			</Link>
			<Link to="/queries" className="nav-link">
				<FiletypeSql className="d-none d-lg-inline" /> Custom Queries Setup
			</Link>
			<label
				role="button"
				onClick={() => dispatch(showImpersonateModal())}
				className="nav-link"
			>
				<Eyeglasses className="d-none d-lg-inline" /> View as Customer
			</label>
			<Link to="/orders" className="mx-3 nav-link">
				<Bag className="d-none d-lg-inline" /> Orders
			</Link>
			<Link to="/quotes" className="mx-3 nav-link">
				<ClipboardData className="d-none d-lg-inline" /> Quotes
			</Link>
			<Link to="/invoices" className="mx-3 nav-link">
				<Bank className="d-none d-lg-inline" /> Invoices
			</Link>
			<Link to="/shipments" className="mx-3 nav-link">
				<Truck className="d-none d-lg-inline" /> Shipments
			</Link>
			<Link to="/rmas" className="mx-3 nav-link">
				<Tools className="d-none d-lg-inline" /> RMAs
			</Link>
			<Link to="/helpdesks" className="mx-3 nav-link">
				<CardText className="d-none d-lg-inline" /> Cases
			</Link>
			<Link to="/queries" className="mx-3 nav-link">
				<Table className="d-none d-lg-inline" /> Custom Queries
			</Link>
		</>
	)
}

const CustomerMenuOptions = () => {
	const dispatch = useDispatch()
	const { currentCompany } = useAuth()
	return (
		<>
			{isClient(currentCompany) ? (
				<label
					role="button"
					onClick={() => dispatch(showImpersonateModal())}
					className="nav-link"
				>
					<Eyeglasses className="d-none d-lg-inline" />
					View as Customer
				</label>
			) : (
				<label className="nav-link" style={{ maxWidth: '15rem' }}>
					{currentCompany?.attributes.name}
				</label>
			)}
			<Link to="/home" className="nav-link" key="home">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			{!currentCompany.settings.orderDisableMenu && (
				<Link to="/orders" className="nav-link" key="orders">
					<Bag className="d-none d-lg-inline" /> Orders
				</Link>
			)}
			{!currentCompany.settings.quoteDisableMenu && (
				<Link to="/quotes" className="nav-link" key="quotes">
					<ClipboardData className="d-none d-lg-inline" /> Quotes
				</Link>
			)}
			{!currentCompany.settings.invoiceDisableMenu && (
				<Link to="/invoices" className="nav-link" key="invoices">
					<Bank className="d-none d-lg-inline" /> Invoices
				</Link>
			)}
			{!currentCompany.settings.shipmentDisableMenu && (
				<Link to="/shipments" className="nav-link" key="shipments">
					<Truck className="d-none d-lg-inline" /> Shipments
				</Link>
			)}
			{!currentCompany.settings.rmaDisableMenu && (
				<Link to="/rmas" className="nav-link" key="rmas">
					<Tools className="d-none d-lg-inline" /> RMAs
				</Link>
			)}
			{!currentCompany.settings.helpdeskDisableMenu && (
				<Link to="/helpdesks" className="nav-link">
					<CardText className="d-none d-lg-inline" /> Cases
				</Link>
			)}
			{!currentCompany.settings.queryDisableMenu && (
				<Link to="/queries" className="nav-link" key="queries">
					<Table className="d-none d-lg-inline" /> Custom Queries
				</Link>
			)}
		</>
	)
}

const ClientMenuOptions = () => {
	const dispatch = useDispatch()
	const { currentCompany } = useAuth()

	return (
		<>
			{isClient(currentCompany) && (
				<label
					role="button"
					onClick={() => dispatch(showImpersonateModal())}
					className="nav-link"
				>
					<Eyeglasses className="d-none d-lg-inline" />
					View as Customer
				</label>
			)}
			<Link to="/home" className="nav-link">
				<House className="d-none d-lg-inline" /> Home
			</Link>
			{!currentCompany.settings.orderDisableMenu && (
				<Link to="/orders" className="nav-link">
					<Bag className="d-none d-lg-inline" /> Orders
				</Link>
			)}
			{!currentCompany.settings.quoteDisableMenu && (
				<Link to="/quotes" className="nav-link">
					<ClipboardData className="d-none d-lg-inline" /> Quotes
				</Link>
			)}
			{!currentCompany.settings.invoiceDisableMenu && (
				<Link to="/invoices" className="nav-link">
					<Bank className="d-none d-lg-inline" /> Invoices
				</Link>
			)}
			{!currentCompany.settings.shipmentDisableMenu && (
				<Link to="/shipments" className="nav-link">
					<Truck className="d-none d-lg-inline" /> Shipments
				</Link>
			)}
			{!currentCompany.settings.rmaDisableMenu && (
				<Link to="/rmas" className="nav-link" key="rmas">
					<Tools className="d-none d-lg-inline" /> RMAs
				</Link>
			)}
			{!currentCompany.settings.helpdeskDisableMenu && (
				<Link to="/helpdesks" className="nav-link">
					<CardText className="d-none d-lg-inline" /> Cases
				</Link>
			)}
			{!currentCompany.settings.queryDisableMenu && (
				<Link to="/queries" className="nav-link" key="queries">
					<Table className="d-none d-lg-inline" /> Custom Queries
				</Link>
			)}
		</>
	)
}

const MenuOptions = () => {
	return <Options />
}

export default MenuOptions
