import { Modal } from 'react-bootstrap'
import NewQuote from './new-quote/new.quote.component'

const QuoteForm = ({
	show = false,
	mode = 'create',
	onClose,
	onOperationCompleted,
}) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new Quote has been added. Quote number: ${data.quoteNumber}`,
		)
		onClose?.()
	}

	const FormContent = () => {
		switch (mode) {
			case 'create':
				return <NewQuote onSubmitted={notifyCreated} />
			default:
				throw new Error('Unrecognized mode.')
		}
	}

	return (
		<Modal
			fullscreen
			show={show}
			onHide={onClose}
			animation
			contentClassName="p-3"
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Quote</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default QuoteForm
