import { useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { accessors } from './query.table.accessors.component'
import _ from 'lodash'

const QueriesTable = ({
	queries,
	downloadQuery,
	onEditQuery,
	onDeleteQuery,
	onRunQuery,
	companyRole,
}) => {
	const columns = useMemo(
		() =>
			accessors(
				downloadQuery,
				onEditQuery,
				onDeleteQuery,
				onRunQuery,
				companyRole,
			),
		[queries],
	)
	const data = useMemo(() => queries)
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data },
		useSortBy,
	)

	const TableHead = () => {
		return (
			<thead>
				{headerGroups.map((headerGroup) => {
					const { key: headerGroupKey, ...headerGroupProps } =
						headerGroup.getHeaderGroupProps()
					return (
						<tr key={headerGroupKey} {...headerGroupProps}>
							{headerGroup.headers.map((column) => {
								const { key: headerKey, ...headerProps } =
									column.getHeaderProps(column.getSortByToggleProps())
								return (
									<th
										key={headerKey}
										{...headerProps}
										className={column.className}
									>
										{column.render('Header')}
										<span>
											{!column.isSorted
												? ''
												: column.isSortedDesc
													? ' ▼'
													: ' ▲'}
										</span>
									</th>
								)
							})}
						</tr>
					)
				})}
			</thead>
		)
	}

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()
					return (
						<tr key={rowKey} {...rowProps}>
							{row.cells.map((cell) => {
								const { key: cellKey, ...cellProps } = cell.getCellProps()
								return (
									<td
										key={cellKey}
										{...cellProps}
										className={cell.column.className}
									>
										{cell.render('Cell')}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(queries) ? (
		<BTable hover {...getTableProps()}>
			<TableHead />
			<TableBody />
		</BTable>
	) : (
		<p>No custom queries have been added. Let's create one!</p>
	)
}

export default QueriesTable
