import moment from 'moment'
import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printOrder) => [
	{
		Header: 'Orders List',
		columns: [
			{
				Header: 'Order#',
				accessor: 'orderNum',
			},
			{
				Header: 'Status',
				accessor: (item) => item.openOrder && <Badge bg="success">Open</Badge>,
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCodeDescription',
			},
			{
				Header: 'Order Date',
				accessor: (item) =>
					item.orderDate != null
						? moment(item.orderDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null
						? moment(item.needByDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Order Amount',
				accessor: (item) =>
					Intl.NumberFormat(navigator.language, {
						style: 'currency',
						currency: item.currencyCodeCurrencyID.toLowerCase(),
					}).format(
						item.docTotalCharges + item.docTotalTax + item.docTotalMisc,
					),
			},
			{
				Header: '⚡',
				accessor: (order) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printOrder(order.orderNum)}
								>
									Download Order
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
