import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import { useRefresher } from '../shared/useRefresher'
import QuoteForm from './quote.form.component'
import QuotesList from './quotes.list.component'

const Quotes = () => {
	const { currentCompany } = useAuth()
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const refresher = useRefresher()

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Quotes</Breadcrumb.Item>
				</Breadcrumb>
				{!currentCompany.settings.orderDisableCreate && (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setMode('create')
							setShowForm(true)
						}}
					>
						Create New Quote
					</Button>
				)}
			</div>
		)
	}

	return (
		<>
			<HeaderComponent />
			<QuotesList refresher={refresher} />
			<QuoteForm
				show={showForm}
				mode={mode}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster />
		</>
	)
}

export default Quotes
