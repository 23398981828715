//TODO: Move this to Customer service
import http from '../../http-common'

export const findAll = (customerId, token) => {
	return http.get(`/api/customers/${customerId}/quotes`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const getLines = (customerId, quoteNum, token) => {
	return http.get(`/api/customers/${customerId}/quotes/${quoteNum}/lines`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const create = (data, environmentId, companyId, customerId, token) => {
	return http.post(
		`/api/customers/${customerId}/quotes`,
		{ data, environmentId, companyId },
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const print = (customerId, quoteNum, token) => {
	return http.get(`/api/customers/${customerId}/quotes/${quoteNum}/print`, {
		headers: {
			'x-access-token': token,
		},
		responseType: 'blob',
	})
}
