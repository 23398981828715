import moment from 'moment'
import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printQuote) => [
	{
		Header: 'Quotes List',
		columns: [
			{
				Header: 'Quote#',
				accessor: 'quoteNum',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					!item.quoteClosed && <Badge bg="success">Open</Badge>,
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCode',
			},
			{
				Header: 'Quote Date',
				accessor: (item) =>
					item.quoteDate != null
						? moment(item.entryDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null
						? moment(item.needByDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Quote Amount',
				accessor: (item) =>
					Intl.NumberFormat(navigator.language, {
						style: 'currency',
						currency: item.currencyCodeCurrencyID.toLowerCase(),
					}).format(item.docTotalQuote),
			},
			{
				Header: '⚡',
				accessor: (quote) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printQuote(quote.quoteNum)}
								>
									Download Quote
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
