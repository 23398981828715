import moment from 'moment'
import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printRMA) => [
	{
		Header: 'RMA List',
		columns: [
			{
				Header: 'RMA #',
				accessor: 'rMANum',
			},
			{
				Header: 'RMA Date',
				accessor: (item) =>
					item.rMADate != null ? moment(item.rMADate).format('YYYY-MM-DD') : '', //rMADate is how api defines it
			},
			{
				Header: 'Invoice #',
				accessor: 'invoiceNum',
			},
			{
				Header: 'Open RMA',
				accessor: (item) =>
					item.openRMA ? <Badge bg="success">Open</Badge> : '',
			},
			{
				Header: '⚡',
				accessor: (item) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printRMA(item.rMANum)}
								>
									Download RMA
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
