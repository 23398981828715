import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import _ from 'lodash'

const RecordLinesTable = ({ recordLines, linesAccessors }) => {
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns: linesAccessors, data: recordLines },
		useSortBy,
	)

	const TableHead = () => {
		return (
			<thead>
				{headerGroups.map((headerGroup) => {
					const { key: headerGroupKey, ...headerGroupProps } =
						headerGroup.getHeaderGroupProps()
					return (
						<tr key={headerGroupKey} {...headerGroupProps}>
							{headerGroup.headers.map((column) => {
								const { key: headerKey, ...headerProps } =
									column.getHeaderProps(column.getSortByToggleProps())
								return (
									<th key={headerKey} {...headerProps}>
										{column.render('Header')}
										<span>
											{!column.isSorted
												? ''
												: column.isSortedDesc
													? ' ▼'
													: ' ▲'}
										</span>
									</th>
								)
							})}
						</tr>
					)
				})}
			</thead>
		)
	}

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()
					return (
						<tr key={rowKey} {...rowProps}>
							{row.cells.map((cell) => {
								const { key: cellKey, ...cellProps } = cell.getCellProps()
								return (
									<td key={cellKey} {...cellProps}>
										{cell.render('Cell')}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(recordLines) ? (
		<BTable
			responsive
			hover
			{...getTableProps()}
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.075)' }}
		>
			<TableHead />
			<TableBody />
		</BTable>
	) : (
		<p>There are currently no lines.</p>
	)
}

export default RecordLinesTable
