import useAuth, { useCustomerId } from '../../auth/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { EpicorSharedService } from '../../services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import HelpDesksTable from './helpdesks.table.component'

const HelpDesksList = ({ refresher }) => {
	const { user } = useAuth()
	const [cases, setCases] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchCases = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorSharedService.findAll(
				customerId,
				'helpdesks',
				user.accessToken,
			)
			setCases(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [customerId, user.accessToken])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchCases()
		}
	}, [customerId, fetchCases, refresher.id])

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<HelpDesksTable cases={cases} /> //TODO: Display Details on Click
			)}
		</div>
	)
}

export default HelpDesksList
