import useAuth, { useCustomerId } from '../../auth/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import {
	EpicorOrderService,
	EpicorQuoteService,
	EpicorRMAService,
	EpicorInvoiceService,
	EpicorShipmentService,
} from '../../services/epicor'
import { containerItem } from './styles'
import LoadingParagraphs from './loading/loading.paragraph.component'
import LinesTable from './lines.table.component'

const serviceMap = {
	order: EpicorOrderService,
	quote: EpicorQuoteService,
	rma: EpicorRMAService,
	invoice: EpicorInvoiceService,
	shipment: EpicorShipmentService,
}

const RecordLines = ({ record, type, linesAccessors }) => {
	const { user } = useAuth()
	const [recordLines, setRecordLines] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchRecordLines = useCallback(async () => {
		setLoading(true)
		try {
			const response = await serviceMap[type].getLines(
				customerId,
				record,
				user.accessToken,
			)
			setRecordLines(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [customerId, record, type, user.accessToken])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchRecordLines()
		}
	}, [customerId, fetchRecordLines])

	return (
		<div style={{ ...containerItem, backgroundColor: 'var(--bs-table-bg)' }}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={3} />
			) : (
				<LinesTable recordLines={recordLines} linesAccessors={linesAccessors} />
			)}
		</div>
	)
}

export default RecordLines
