import { Container } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'

const Footer = () => {
	const { currentCompany } = useAuth()
	return (
		<footer className="bg-light text-center text-lg-start">
			<Container fluid className="text-center p-3 small">
				{currentCompany.company.name}
				{currentCompany.settings.companyPrivacy && (
					<>
						{' | '}
						<a href={currentCompany.settings.companyPrivacy}>Privacy</a>
					</>
				)}
				{currentCompany.settings.companyTandC && (
					<>
						{' | '}
						<a href={currentCompany.settings.companyTandC}>
							Terms and Conditions
						</a>
					</>
				)}
				<br />© {new Date().getFullYear()}{' '}
				<a href="https://www.elevateerp.com">ElevateERP</a>. All rights
				reserved.
			</Container>
		</footer>
	)
}

export default Footer
