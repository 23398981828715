import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import OrderForm from './order.form.component'
import OrdersList from './orders.list.component'
import useAuth from '../../auth/useAuth'
import { useRefresher } from '../shared/useRefresher'

const Orders = () => {
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const refresher = useRefresher()
	const { currentCompany } = useAuth()

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Orders</Breadcrumb.Item>
				</Breadcrumb>
				{!currentCompany.settings.orderDisableCreate && (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setMode('create')
							setShowForm(true)
						}}
					>
						Create New Order
					</Button>
				)}
			</div>
		)
	}

	return (
		<>
			<HeaderComponent />
			<OrdersList refresher={refresher} />
			<OrderForm
				show={showForm}
				mode={mode}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster />
		</>
	)
}

export default Orders
