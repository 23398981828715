import useAuth from '../../auth/useAuth'
import { Modal, Button, Row, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setImpersonatedCustomer } from '../../state/impersonatedCustomer'
import { useEffect, useState, useRef } from 'react'
import { customersList } from '../../services/customer.service'
import Select from 'react-select'

const ImpersonateModal = ({ show, onSelect, onClose }) => {
	const { user, currentCompany } = useAuth()
	const [customers, setCustomers] = useState([])
	const [selectedCustomerId, setSelectedCustomerId] = useState(null)
	const dispatch = useDispatch()
	const isLoaded = useRef(false)

	useEffect(() => {
		if (currentCompany && currentCompany.company && show && !isLoaded.current) {
			customersList(
				user.accessToken,
				currentCompany.company.id,
				currentCompany.company.environmentId,
			).then((response) => {
				setCustomers(response.data)
				isLoaded.current = true
			})
		}
	}, [currentCompany, show, user.accessToken])

	const customerOptions = customers.map((c) => ({
		value: c.id,
		label: `${c.name} (${c.custId})`,
	}))

	const dispatchImpersonate = (e) => {
		const cust = customers.find((c) => c.id === parseInt(selectedCustomerId))
		dispatch(setImpersonatedCustomer(cust))
		onSelect()
	}

	return (
		<Modal
			size="lg"
			show={show}
			animation
			contentClassName="p-3"
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>View as Customer</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="d-grid gap-4 mt-4">
					<Form.Group controlId="form.shipTo">
						<Form.Label>Select customer you want to impersonate</Form.Label>
						<Select
							options={customerOptions}
							onChange={(selectedOption) =>
								setSelectedCustomerId(selectedOption.value)
							}
							isSearchable={true}
						/>
					</Form.Group>
					<Button
						variant="primary"
						onClick={dispatchImpersonate}
						disabled={!selectedCustomerId}
					>
						View as Customer
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default ImpersonateModal
