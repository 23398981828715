import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Container } from 'react-bootstrap'
import { resetPassword } from '../../auth/useAuth'
import { centeredSquareForm } from '../shared/styles'

const ResetPassword = () => {
	const navigate = useNavigate()
	const [disableBtn, setDisableLoginButton] = useState(true)
	const [errorMsg, setErrorMsg] = useState(null)
	const [sent, setSent] = useState(false)

	// authenticate against Signin API.  if the returned user has more then companies solicit
	// which one he wants to home into
	const reset = async (event) => {
		event.preventDefault()
		const email = event.target.email?.value
		try {
			const user = await resetPassword(email)
			if (user) {
				setSent(true)
			} else {
				setErrorMsg('Invalid email')
			}
		} catch (error) {
			setErrorMsg('Invalid email.')
		}
	}

	const startOver = (e) => {
		e.preventDefault()
		navigate('/login')
	}

	function checkFieldsFilled(e) {
		setDisableLoginButton(!e.currentTarget.form.checkValidity())
	}
	return !sent ? (
		<Container>
			<Form
				style={centeredSquareForm}
				autoComplete="on"
				onSubmit={reset}
				className="needs-validation"
				noValidate
			>
				<h2 className="text-center">Reset Password</h2>
				<div className="d-grid gap-4">
					<Form.Group controlId="email">
						<Form.Control
							required
							size="lg"
							type="text"
							placeholder="Email"
							onChange={checkFieldsFilled}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter email address
						</Form.Control.Feedback>
					</Form.Group>
					<Button
						size="lg"
						variant={disableBtn ? 'secondary' : 'primary'}
						type="submit"
						disabled={disableBtn}
					>
						Reset Password
					</Button>
					<span style={{ color: 'red' }}>{errorMsg}</span>
				</div>
				<a href="/" onClick={startOver}>
					Back to Login
				</a>
			</Form>
		</Container>
	) : (
		<Container style={centeredSquareForm}>
			<i
				className="bi bi-check-circle"
				style={{ fontSize: '5rem', color: '#11BD22' }}
			></i>
			<p>A link has been sent to your email inbox to reset your password.</p>
			<a href="/" onClick={startOver}>
				Back to Login
			</a>
		</Container>
	)
}

export default ResetPassword
